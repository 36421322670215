<template>
	<div class="wrap" ondragstart="return false;" oncontextmenu="return false;">
		<!-- <div class="goappstore_bg_bg">
			<img
				:style="{ height: height + 'px' }"
				v-if="isGuide"
				src="../assets/images/goappstore/goappstore_bg.jpg"
				mode="heightFix"
				alt=""
			/>
		</div> -->
		<div :class="isGuide ? 'guide_out' : ''">
			<div class="guide" v-if="isGuide">
				<img
					class="arrowhead_icon"
					src="../assets/images/goappstore/arrowhead_icon.png"
					alt=""
				/>
				<div class="guide_title flexCenter">
					<img
						class="browser_icon"
						src="../assets/images/goappstore/browser_icon.png"
						alt=""
					/>
					点击右上角选择浏览器打开
				</div>
				<div class="guide_desc">
					如浏览器打开失败，请移步桌面直接打开应用
				</div>
			</div>
		</div>
		<!-- 头 -->
		<div class="head">
			<div>
				<img src="../assets/images/pc/logo.png" alt="" />
				<span>德语学习背单词</span>
			</div>
		</div>
		<!--  下载 -->
		<div class="introduced">
			<div class="main">
				<div class="titleBox">
					<img
						src="../assets/images/pc/title.png"
						class="title"
						alt=""
					/>
					<img src="../assets/images/pc/APP.png" class="app" alt="" />
					<div class="txt">每天十分钟，德语变轻松</div>
					<div class="downloadBox">
						<div
							class="download"
							@click="gotoDownload"
							v-if="isIos"
						>
							<img
								src="../assets/images/mobile/iphone.png"
								alt=""
							/>
							<div>
								<div>App Store</div>
								<div>下载</div>
							</div>
						</div>
						<div
							class="download"
							@click="gotoDownload"
							v-if="isAndroid"
						>
							<!-- 
							:style="{ margin: isAndroid ? '0 60px 0 0' : '' }" -->
							<img src="../assets/images/pc/android.png" alt="" />
							<div>
								<div>Android</div>
								<div>应用市场下载</div>
							</div>
						</div>
						<a
							href="https://ke.juquedu.com:8007/v3/api/version/germanword.apk"
							target="_blank"
						>
							<div class="download" v-if="isAndroid">
								<img
									src="../assets/images/pc/android.png"
									alt=""
								/>
								<div>
									<div>Android</div>
									<div>在线下载</div>
								</div>
							</div>
						</a>
					</div>
				</div>
				<img
					class="appDetails"
					src="../assets/images/mobile/appDetails.png"
					alt=""
				/>
			</div>
		</div>
		<!-- 内容 -->
		<div class="content">
			<div class="detailsBox" ref="teach">
				<div class="txtBox">
					<img src="../assets/images/mobile/teach.png" alt="" />
					<div class="headline">精选词典 海量词汇</div>
					<div class="text">
						<div>精选德语词典</div>
						<div>名师精心录制</div>
						<div>分为八大词典</div>
						<div>单词数超过1万</div>
					</div>
				</div>
				<div class="imgBox">
					<img src="../assets/images/mobile/phone1.png" alt="" />
					<img
						src="../assets/images/pc/icon1.png"
						style="left: 150px"
						alt=""
					/>
				</div>
			</div>

			<div class="detailsBox" ref="practise">
				<div class="txtBox">
					<img src="../assets/images/mobile/practice.png" alt="" />
					<div class="headline">单词练习</div>
					<div class="text">
						<div>涵盖广A1到B2</div>
						<div>练听力</div>
						<div>练单词拼写</div>
						<div>练口语</div>
					</div>
				</div>
				<div class="imgBox">
					<img src="../assets/images/mobile/phone2.png" alt="" />
					<img
						src="../assets/images/pc/icon2.png"
						style="left: -150px"
						alt=""
					/>
				</div>
			</div>

			<div class="detailsBox" ref="evaluation">
				<div class="txtBox">
					<img src="../assets/images/mobile/appraisal.png" alt="" />
					<div class="headline">练习评分</div>
					<div class="text">
						<div>练习评分</div>
						<div>自动判定用户对错</div>
						<div>系统立刻评判</div>
						<div>即时给出成绩</div>
					</div>
				</div>
				<div class="imgBox">
					<img src="../assets/images/mobile/phone3.png" alt="" />
					<img
						src="../assets/images/pc/icon3.png"
						style="left: 150px"
						alt=""
					/>
				</div>
			</div>

			<div class="detailsBox" ref="test">
				<div class="txtBox">
					<img src="../assets/images/mobile/test.png" alt="" />
					<div class="headline">测评练习</div>
					<div class="text">
						<div>先听音再朗读</div>
						<div>全对话模拟学习</div>
						<div>7种题型，十万种组合</div>
						<div>听力默写交汇学习，避免盲区</div>
					</div>
				</div>
				<div class="imgBox">
					<img src="../assets/images/mobile/phone4.png" alt="" />
					<img
						src="../assets/images/pc/icon4.png"
						style="left: -150px"
						alt=""
					/>
				</div>
			</div>
		</div>
		<div class="footer">
			<div class="footerContent">
				<div class="codeBox">
					<div>
						<img src="../assets/images/pc/code2.png" alt="" />
						<div class="mintxt">公众号</div>
					</div>
					<div>
						<img src="../assets/images/pc/code.png" alt="" />
						<div class="mintxt">商务合作</div>
					</div>
				</div>
				<div class="address">
					<div>
						Copyright © 2017-2023 长沙聚趣教育科技有限公司版权所有。
					</div>
					<div>
						<a href="http://beian.miit.gov.cn"
							>备案号 : 湘ICP备2021002148号-1</a
						>
					</div>
					<div>
						公司地址 : 湖南省长沙市岳麓区麒云路九天银河产业园4栋7楼
					</div>
					<div>联系方式 : 400-862-8806</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { isWeixin } from '../utils/common'
export default {
	components: {},
	name: 'Home',
	data() {
		return {
			isAndroid: false,
			isIos: false,
			isGuide: false,
			height: window.screen.height
		}
	},
	mounted() {
		if (isWeixin()) {
			this.isGuide = true
		}
		// alert(window.screen.width)
	},
	created() {
		var u = navigator.userAgent
		if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
			this.isAndroid = true
		} else {
			this.isIos = true
		}
		document.body.addEventListener('touchstart', function() {})
	},
	methods: {
		gotoDownload() {
			window.location.href =
				'https://german.juquedu.com/h5/goappstoreWord'
		}
	}
}
</script>
<style lang="scss" scoped>
a {
	color: #8c8c97;
	text-decoration: none;
}
.guide_out {
	width: 100%;
	height: 100%;
	background: #000000;
	opacity: 0.6;
	position: fixed;
	z-index: 9999;
	.guide {
		position: absolute;
		top: 16px;
		right: 16px;
		text-align: right;
		.arrowhead_icon {
			width: 185px;
			height: 94px;
		}
		.guide_title {
			font-size: 16px;
			font-weight: bold;
			color: #ffffff;
			margin: 12px 0 9px;
			text-align: right;
			.browser_icon {
				width: 20px;
				height: 20px;
				margin-right: 8px;
			}
		}
		.guide_desc {
			font-size: 11px;
			font-weight: 400;
			color: #ffffff;
			text-align: right;
		}
	}
}
.wrap {
	margin: 0 auto;
	min-width: 360px;
	.goappstore_bg_bg {
		width: 100%;
		background: #ffffff;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9999;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.head {
		width: 360px;
		margin: 0 auto;
		height: 44px;
		display: flex;
		div {
			display: flex;
			align-items: center;
			img {
				width: 28px;
				height: 28px;
			}
			span {
				margin-left: 10px;
				font-size: 14px;
				font-weight: bold;
			}
		}
	}
	.introduced {
		background: url('../assets/images/pc/headBj.png');
		background-size: 80%;
		height: 520px;
		overflow: hidden;
		.main {
			height: 600px;
			width: 360px;
			margin: 0 auto;
			.titleBox {
				padding-top: 20px;
				width: 360px;
				position: relative;
				img {
					display: block;
					margin: 0 auto;
				}
				.title {
					width: 190px;
				}
				.app {
					width: 219px;
				}
				.txt {
					font-size: 14px;
					color: #ffffff;
					text-align: center;
				}
				.downloadBox {
					width: 280px;
					display: flex;
					margin: 0 auto;
					margin-top: 28px;
					.download {
						margin: 0 auto;
						width: 112px;
						height: 44px;
						border-radius: 8px;
						background-color: #31160f;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-bottom: 32px;
						img {
							margin: 0 6px 0 0;
							height: 20px;
						}
						div {
							line-height: 18px;
							// width: 40px;
							color: #fff;
							font-size: 11px;
						}
					}
					.download:hover:active {
						background-color: #793625;
					}
				}
			}
			.appDetails {
				width: 360px;
			}
		}
	}
	.content {
		min-width: 360px;
		margin: 0 auto;
		.detailsBox {
			margin-top: 24px;
			.txtBox {
				text-align: center;
				img {
					width: 296px;
				}
				.headline {
					font-size: 20px;
					font-weight: bold;
				}
				.text {
					margin-top: 30px;
					font-size: 15px;
					color: #919191;
					div {
						margin: 10px;
					}
				}
			}
			.imgBox {
				height: 400px;
				padding-top: 20px;
				margin: 0 auto;
				overflow: hidden;
				img {
					display: block;
					margin: 0 auto;
				}
				img:nth-child(1) {
					position: relative;
					width: 302px;
				}
				img:nth-child(2) {
					position: relative;
					top: -210px;
					width: 86px;
					z-index: 99;
				}
			}
		}
	}
	.footer {
		background-color: #262636;
		height: 282px;
		.footerContent {
			min-width: 360px;
			margin: 0 auto;
			.address {
				margin-top: 20px;
				font-size: 12px;
				color: #8c8c97;
				div {
					margin: 8px 20px;
				}
			}
			.codeBox {
				padding-top: 24px;
				display: flex;
				text-align: center;
				justify-content: center;
				font-size: 12px;
				color: #8c8c97;
				img {
					height: 110px;
					width: 110px;
					margin-left: 20px;
					margin-right: 20px;
				}
				.mintxt {
					margin-top: 8px;
				}
			}
		}
	}
}
</style>
