// 时间戳转换
function timestampToTime(timestamp, type) {
	const dt = new Date(parseInt(timestamp))
	const y = dt.getFullYear()
	const m = (dt.getMonth() + 1 + '').padStart(2, '0')
	const d = (dt.getDate() + '').padStart(2, '0')
	const hh = (dt.getHours() + '').padStart(2, '0')
	const mm = (dt.getMinutes() + '').padStart(2, '0')
	const ss = (dt.getSeconds() + '').padStart(2, '0')
	if (type == 1) {
		return `${y}/${m}/${d} ${hh}:${mm}:${ss}`
	} else if (type == 2) {
		return `${hh}:${mm}:${ss} `
	} else {
		return `${y}/${m}/${d} `
	}
}

/**
 * @description 通过参数字段名获取参数值
 * @param name
 * @returns {string|null}
 */
function getUrlParamByName(name) {
	let href = location.href
	// href = href.replaceAll('%3D','=').replaceAll('%3F','&')
	return (
		decodeURIComponent(
			(new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
				href
			) || ['', ''])[1].replace(/\+/g, '%20')
		) || null
	)
}

// 判断是否在微信
function isWeixin() {
	return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
}

function goToWechat(wechatID) {
	var u = navigator.userAgent
	if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
		window.android.wechat(wechatID)
	} else {
		//ios方法
		window.webkit.messageHandlers.wechat.postMessage(wechatID)
	}
}

//关闭h5页面返回app上级页面
function qwe() {
	var u = navigator.userAgent
	if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
		window.android.qwe()
	} else {
		//ios方法
		window.webkit.messageHandlers.qwe.postMessage({})
	}
}

// 分享到微信和朋友圈功能
function shareQZ(data) {
	var u = navigator.userAgent
	if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
		window.android.shareQZ(data.title, data.text, data.url, data.type)
	} else {
		//ios方法
		window.webkit.messageHandlers.shareQZ.postMessage(data)
	}
}

// 跳转到小程序
function goToMiniApp(data) {
	let params = {}
	if (data.appId) {
		params.appId = data.appId
		params.pages = data.url
	}
	var u = navigator.userAgent
	if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
		window.android.goToMiniApp(params.appId, params.pages)
	} else {
		//ios方法
		window.webkit.messageHandlers.goToMiniApp.postMessage(params)
	}
}

//替换
function subClone(obj, originObj) {
	let objClone = {}
	if (
		obj &&
		typeof obj === 'object' &&
		originObj &&
		typeof originObj === 'object'
	) {
		for (var key in obj) {
			if (Object.prototype.hasOwnProperty.call(obj, key)) {
				if (originObj[key]) {
					objClone[key] = originObj[key]
				} else {
					objClone[key] = obj[key]
				}
			}
		}
	}
	return objClone
}

//判断是否在app中打开
function isWeb() {
	let version = sessionStorage.getItem('version')
	return !!(version && version != undefined)
}

//是否是pc打开
function getIsPc() {
	var sUserAgent = window.navigator.userAgent.toLowerCase()
	var bIsIpad = sUserAgent.match(/ipad/i) == 'ipad'
	var bIsIphoneOs = sUserAgent.match(/iphone os/i) == 'iphone os'
	var bIsMidp = sUserAgent.match(/midp/i) == 'midp'
	var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == 'rv:1.2.3.4'
	var bIsUc = sUserAgent.match(/ucweb/i) == 'ucweb'
	var bIsAndroid = sUserAgent.match(/android/i) == 'android'
	var bIsCE = sUserAgent.match(/windows ce/i) == 'windows ce'
	var bIsWM = sUserAgent.match(/windows mobile/i) == 'windows mobile'
	if (
		bIsIpad ||
		bIsIphoneOs ||
		bIsMidp ||
		bIsUc7 ||
		bIsUc ||
		bIsAndroid ||
		bIsCE ||
		bIsWM
	) {
		return false
	} else {
		return true
	}
}

export {
	timestampToTime,
	getUrlParamByName,
	isWeixin,
	goToWechat,
	qwe,
	shareQZ,
	goToMiniApp,
	subClone,
	isWeb,
	getIsPc
}
